export const LOAD_STATUS = {
  NOT_LOADED: 'NOT_LOADED',
  LOADING:    'LOADING',
  LOADED:     'LOADED',
  ERROR:      'ERROR'
};
export const AUTH_STATUS = {
    NO_AUTH:                'NO_AUTH',
    AUTH_FETCHING_ROLES:    'AUTH_FETCHING_ROLES',
    AUTH_HAS_ROLES:         'AUTH_HAS_ROLES',
    AUTH_HAS_NO_ROLES:      'AUTH_HAS_NO_ROLES'
};
export const TERMS = {
  "SPRING": {order: 1, name: "Spring"},
  "SUMMER_1": {order: 2.0, name: "Summer 1"},
  "SUMMER_11_WEEK": {order: 2.1, name: "Summer 11 Week"},
  "SUMMER_2": {order: 2.2, name: " Summer 2"},
  "FALL": {order: 3, name: "Fall"}
};


export const SESSION_LENGTH_HOURS = 12;
export const SESSION_WARNING_MINS = 5;
//export const SESSION_EXT_HOURS = 0;

export const FUNDING_SOURCE_TYPES = {
  "lps" : "LPS Funded Position (For Use by LPS Office Only)",
  "dept" : "Department Operating Budget (e.g. 000000 fund)",
  "endowment" : "Endowment Fund (e.g. 4xxxxx)",
  "sponsored" : "Sponsored Research / Grant (e.g. 5xxxxx)",
  "gift" : "Gift Fund (e.g. 6xxxxx)",
  "other-internal" : "Other Internal Fund (e.g. 010201)",
  "other": "Other Support (e.g. foreign government, external fellowship)"
};

/**
 * Compare two terms to determine which is great or less
 * @param term1
 * @param term2
 * @return {number}: -1 for term1 less than term2, 0 for equal, 1 for term1 greater than term 2
 */
export const compareTerms = function(term1, term2) {
  //if they are equal we are done
  if(term1 === term2) return 0;

  //if the years are different just compare years
  let year1 = term1.substr(0,4);
  let year2 = term2.substr(0,4);
  if(year1 < year2) return -1;
  if(year1 > year2) return 1;

  //if the years match but term doens't then compare term order
  let termOrder1 = TERMS[term1.substr(5)];
  let termOrder2 = TERMS[term2.substr(5)];
  if(termOrder1.order < termOrder2.order) return -1;
  return 1;

};

/**
 * Convert a term code into an srs term (term codes are 20XX_FALL, 20XX_SUMMER_1 etc)
 * @param termCode
 * @return {string}
 */
export const termCodeToSrsTerm = function(termCode) {
  if(termCode.toLowerCase().indexOf('spring') > -1) return termCode.substr(0, 4) + 'A';
  if(termCode.toLowerCase().indexOf('summer') > -1) return termCode.substr(0, 4) + 'B';
  if(termCode.toLowerCase().indexOf('fall') > -1) return termCode.substr(0, 4) + 'C';
  return "";
};
