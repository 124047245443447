export const onUpdateCaseKickoffCustom = /* GraphQL */ `
  subscription OnUpdateCaseKickoff($owner: String!) {
    onUpdateCaseKickoff(owner: $owner) {
      id
      status
      interfolioId
      owner
      warnings {
        message
        detail
      }
    }
  }
`;
