import API from '@aws-amplify/api';
import { graphqlOperation } from '@aws-amplify/api';
import {LOAD_STATUS} from "./store-utils";
import {subjectAreas, courseHistory} from "../graphql/queries";

export default {
  namespaced: true,
  state: {
    subjectAreas: {
      loadStatus: LOAD_STATUS.NOT_LOADED,
      data: null
    },
    courseHistory: {}, //array hash containing the course history
    instructorSections: {} //array hash containing instructor section history keyed on instructor penn_id
  },
  mutations: {
    addSubjectAreas(state, subjectAreas) {
      state.subjectAreas.loadStatus = LOAD_STATUS.LOADED;
      state.subjectAreas.data = subjectAreas;
    },
    setCourseHistory(state, {courseId, loadStatus, data}) {
      state.courseHistory[courseId] = {
        loadStatus: loadStatus,
        data: data
      };
      //necessary to make sure that vue picks up the changes
      state.courseHistory = JSON.parse(JSON.stringify(state.courseHistory));
    },
    setInstructorSections(state, {instructorPennId, loadStatus, data}) {
      state.instructorSections[instructorPennId] = {
        loadStatus: loadStatus,
        data: data
      };
      //necessary to make sure that vue picks up the changes
      state.instructorSections = JSON.parse(JSON.stringify(state.instructorSections));
    }
  },
  actions: {
    async loadSubjectAreas({commit, state}) {
      if(state.subjectAreas.loadStatus === LOAD_STATUS.NOT_LOADED) {
        API.graphql(graphqlOperation(subjectAreas))
          .then((response) => {
            commit('addSubjectAreas', response.data.subjectAreas);
          })
          .catch(() => {
            commit('error/addError', "There was an error retrieving Subject Areas from the database", {root: true})
          });
      }
    },

    async loadCourseHistory({commit, state}, {subject, courseNumber}) {
      let courseId = subject + courseNumber;
      if(!state.courseHistory.hasOwnProperty(courseId)) {
        commit('setCourseHistory', {courseId, loadStatus: LOAD_STATUS.LOADING, data: null});
        API.graphql(graphqlOperation(courseHistory,{subject: subject, course_num: courseNumber}))
          .then((response) => {
            commit('setCourseHistory', {courseId, loadStatus: LOAD_STATUS.LOADED, data: response.data.courseHistory});
          })
          .catch(() => {
            commit('error/addError', "There was an error retrieving the course enrollment history for " + subject + courseNumber, {root: true});
            commit('setCourseHistory', LOAD_STATUS.ERROR, null);
          })
      }
    }
  },
  getters: {
    subjectAreas: state => {
      if(state.subjectAreas.loadStatus === LOAD_STATUS.LOADED) {
        let subjectAreas = {};
        for(let area of state.subjectAreas.data) {
          subjectAreas[area.code] = area.code + ' - ' + area.description;
        }
        return subjectAreas;
      }
      return [];
    },
    /**
     * Get the enrollment history for a course
     * @param state
     * @param subject
     * @param courseNumber
     * @return {null|*}
     */
    courseHistory:(state) => (subject, courseNumber) => {
      let courseId = subject + courseNumber;
      if(state.courseHistory.hasOwnProperty(courseId)) {
        return state.courseHistory[courseId];
      }
      return {loadStatus: LOAD_STATUS.NOT_LOADED, data: null};
    },

    /**
     * Get the average enrollment history per term and division
     * @param state
     * @return {Function}
     */
    averageCourseEnrollmentHistory:(state) => (subject, courseNumber) => {
      let courseId = subject + courseNumber;
      //first make sure there is data to work with
      if(!state.courseHistory.hasOwnProperty(courseId)) {
        return {loadStatus: LOAD_STATUS.NOT_LOADED, data: null};
      }
      else if(state.courseHistory[courseId].loadStatus !== LOAD_STATUS.LOADED) {
        return state.courseHistory[courseId];
      }

      //loop through section history to create aggregate on term and division
      let terms = {};
      for(let rec of state.courseHistory[courseId].data) {
        let termKey = rec.term + rec.schedule_type;
        if(rec.division) termKey = termKey + rec.division;
        if(!terms.hasOwnProperty(termKey)) {
          terms[termKey] = {
            term: rec.term,
            scheduleType: rec.schedule_type,
            division: rec.division,
            numSections: 0,
            totalEnrollment: 0,
            averageEnrollment: 0
          }
        }
        if(rec.total_enrlmt > 0) {
          terms[termKey].numSections++;
          terms[termKey].totalEnrollment += rec.total_enrlmt;
          terms[termKey].averageEnrollment = Math.round(terms[termKey].totalEnrollment / terms[termKey].numSections);
        }
      }
      //convert into array
      let termArray = [];
      for(let termKey in terms) {
        if(terms[termKey].totalEnrollment > 0) {
          termArray.push(terms[termKey]);
        }
      }

      return {
        loadStatus: LOAD_STATUS.LOADED,
        data: termArray
      };
    },

    /**
     * Get the instructor courses from the last two years where load > 0
     * @param state
     * @return {Function}
     */
    instructorRecentSections:(state) => (instructorPennId) => {
      //first make sure there is data to work with
      if(!state.instructorSections.hasOwnProperty(instructorPennId)) {
        return {loadStatus: LOAD_STATUS.NOT_LOADED, data: null};
      }
      else if(state.instructorSections[instructorPennId].loadStatus !== LOAD_STATUS.LOADED) {
        return state.instructorSections[instructorPennId];
      }

      //return the sections filtered by recent
      let now = new Date();
      let startYear = (now.getYear() - 2).toString()
      let recent = state.instructorSections[instructorPennId].data.filter((section) => {
        return section.term > startYear && section.responsibility_percent > 0;
      });

      return {
        loadStatus: LOAD_STATUS.LOADED,
        data: recent
      };
    }
  }
}
