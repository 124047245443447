
export default {
  namespaced: true,
  state: {
    errors: []
  },
  mutations: {
    addError(state, errorMessage) {
      state.errors.push({message: errorMessage, dismissed: false});
    },
    dismissError(state, errorIndex) {
      state.errors[errorIndex].dismissed = true;
    }
  },
  actions: {
    addError({commit}, errorMessage) {
      commit('addError', errorMessage);
    },
    dismissError({commit}, errorIndex) {
      commit('dismissError', errorIndex);
    }
  },
  getters: {
    lastErrorMessage: state => {
      if(state.errors.length === 0) return null;
      return state.errors[state.errors.length -1].message
    },
    activeErrorMessages: state => {
      let messages = [];
      state.errors.forEach(function(error, index) {
        if(!error.dismissed) messages.push({message: error.message, index: index});
      });
      return messages;
    }
  }
}
