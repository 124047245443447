import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Amplify from "aws-amplify"
import * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'
import awsconfig from "./aws-exports"
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import "../public/custom.scss";
import Vue2TouchEvents from 'vue2-touch-events'

Vue.use(AmplifyPlugin, AmplifyModules)
Vue.use(VueMaterial)
Vue.use(Vue2TouchEvents)
Vue.config.productionTip = false;

Vue.component('MdSelect', Vue.options.components.MdSelect.extend({
  methods: {
    isInvalidValue: function isInvalidValue () {
      return this.$el.validity ? this.$el.validity.badInput : this.$el.querySelector('input').validity.badInput
    }
  }
}));

// override test domain with penn url
if(awsconfig.oauth.domain === "case-kickoff-test.auth.us-west-2.amazoncognito.com") {
  awsconfig.oauth.domain = "case-kickoff-auth-test.irad-dev.sas.upenn.edu";
  awsconfig.oauth.redirectSignIn = "https://case-kickoff.apps-test.sas.upenn.edu/";
  awsconfig.oauth.redirectSignOut = "https://case-kickoff.apps-test.sas.upenn.edu/logout/";
}

Amplify.configure(awsconfig);

store.dispatch("user/init");

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
