/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:41da992a-f898-4ba7-8e3c-c606b75e3df7",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_BnynyVnTK",
    "aws_user_pools_web_client_id": "4qc6oag9erh533orrf24flhosf",
    "oauth": {
        "domain": "case-kickoff-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "openid"
        ],
        "redirectSignIn": "https://casekickoff.apps.sas.upenn.edu/",
        "redirectSignOut": "https://idp.pennkey.upenn.edu/logout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_appsync_graphqlEndpoint": "https://d6ikityndvbthisjqzxokcfsza.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "casekickoff-prod-hosting-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d3akyagvyxvpkn.cloudfront.net"
};


export default awsmobile;
