import {actionAndDocumentTypes} from "../graphql/queries";
import API from '@aws-amplify/api';
import { graphqlOperation } from '@aws-amplify/api';
import {LOAD_STATUS} from "./store-utils";

export default {
  namespaced: true,
  state: {
    titles: null,
    loadStatus: LOAD_STATUS.NOT_LOADED,
    titleActions: [],
    documentTypes: {}
  },
  mutations: {
    setTitleActions(state, titleActions) {
      state.titleActions = titleActions;
      state.loadStatus == LOAD_STATUS.LOADED;
    },
    setDocumentTypes(state, documentTypes) {state.documentTypes = documentTypes},
  },

  actions: {
    async loadData({commit, state}) {
      if (state.loadStatus === LOAD_STATUS.NOT_LOADED) {
        API.graphql(graphqlOperation(actionAndDocumentTypes))
          .then((response) => {
            commit('setTitleActions', response.data.actionAndDocumentTypes.titleActions);
            commit('setDocumentTypes', response.data.actionAndDocumentTypes.documentTypes);
          })
          .catch(() => {
            commit('error/addError', "There was an error retrieving Titles, Actions and Documents from the database", {root: true})
          });
      }
    }
  },
  getters: {
    actionTypes: state => {
      let actionTypes = [];
      for(let action of state.titleActions) {
        if(actionTypes.indexOf(action.actionType) == -1) actionTypes.push(action.actionType);
      }
      return actionTypes.sort();
    },
    documentTypes: state => {
      return state.documentTypes;
    },
    titleActions: state => {
      return state.titleActions
    },
    currentTitleAction: (state, getters, rootState, rootGetters) => {
      let positionData = rootGetters['form/formData'].positionStep;
      if(positionData.title && positionData.caseAction) {
        for(let titleAction of state.titleActions) {
          let formatedTitleWithCase = titleAction.title;
          if(titleAction.specialCase !== null) {
            formatedTitleWithCase = ' - ' + titleAction.specialCase;
          }
          if(titleAction.actionType === positionData.caseAction && formatedTitleWithCase === positionData.title) {
            return titleAction;
          }
        }
      }
      return null;
    },
    titleActionFromTitleActionYears: (state) => ({title, action, years}) => {

      for(let titleAction of state.titleActions) {
        if(titleAction.actionType === action && titleAction.title === title) {
              //default to the non-special case
              if(titleAction.specialCase == null) {
                return titleAction;
              }
              //if years are greater than 3 then use the 4 or greater special case
              else if(years > 3 && titleAction.specialCase.indexOf('4') > -1) {
                return titleAction;
              }
              //if years are 3 or less then use the 3 or less special case
              else if(years <= 3 && titleAction.specialCase.indexOf('3') > -1) {
                return titleAction;
              }
          } else if (!years) {
              // need an option for when years aren't provided
              if (titleAction.actionType === action && (titleAction.title + " - " + titleAction.specialCase === title)) {
                  return titleAction;
              }
          }
      }
      return null;
    },
    documents: (state) => (action, title) => {
        let docList = {};
        for (let titleAction of state.titleActions) {
            if (titleAction.actionType == action && (titleAction.title == title || (titleAction.title + " - " + titleAction.specialCase) == title)) {
                for (let doc of titleAction.documents) {
                    var addDoc = {
                        minAllowed: doc.minAllowed,
                        maxAllowed: doc.maxAllowed,
                        name: state.documentTypes[doc.documentTypeCode].name,
                        shortName: state.documentTypes[doc.documentTypeCode].nameShort,
                        instructions: state.documentTypes[doc.documentTypeCode].instructions
                    };
                    docList[doc.documentTypeCode] = addDoc;
                }
            }
        }
        return docList;
    }
  }
};
