import API from '@aws-amplify/api';
import { graphqlOperation } from '@aws-amplify/api';
import { interfolioUnits, interfolioUserCases, interfolioLecturerInfo, systemStatus } from '../graphql/queries'
import {LOAD_STATUS, termCodeToSrsTerm} from "./store-utils";
import {createCaseKickoff} from "../graphql/mutations";
import {
  getOfferLetterTextFromInterfolioId,
  interfolioCaseDetailFull,
  kickoffInterfolioId
} from "../graphql/queries-custom";
import { onUpdateCaseKickoffCustom} from "../graphql/subscriptions-custom";
import { v4 as uuidv4 } from 'uuid';
import {omitUnsubmittedCommitteeForms} from "../graphql/mutations";


let getDefaultProgress = () => {
  return {
    'Case Initialization': false,
    'Adjust Workflow Steps': false,
    'Department Faculty Affairs Committee Assignment': false,
    'Offer Letter Committee Assignment': false,
    'Department Chair Committee Assignment': false,
    'Associate Dean Committee Assignment': false,
    'Business Office Committee Assignment': false,
    'Update Department Requirements': false,
    'Case Moved Forward for Form Processing': false,
    'Request Form Submitted': false,
    'Teaching Report Generated': false,
    'Case Kickoff Complete': false
  }
}

export const SYSTEM_STATUS = {
  OK: 'ok',
  MAINTENANCE: 'maintenance'
}

export default {
  namespaced: true,
  state: {
    omitUnsubmittedFormsStatus: LOAD_STATUS.NOT_LOADED,
    units: null,
    cases: {
      loadStatus: LOAD_STATUS.NOT_LOADED, //can be NOT_LOADED, LOADING, LOADED, ERROR
      total: null,
      totalActive: null,
      data: [],
      dataIncludesClosedCases: false
    },
    lecturerInfo: {
      loadStatus: LOAD_STATUS.NOT_LOADED,
      data: [],
      idDataMap: {}
    },

    caseDetail: {},
    latestCaseKickoff: {
      id: null,
      interfolioId: null,
      status: "No Kickoff",
      updates: [],
      finished: false,
      progress: getDefaultProgress(),
      caseKickoffData: null,
      addedToList: false
    },
    kickoffInterfolioId: {
      loadStatus: LOAD_STATUS.NOT_LOADED,
      data: [],
      kickoffToInterfolioId: {},
      interfolioToKickoffId: {}
    },
    offerLetters: {},
    caseKickoffSubscriber: null,
    systemStatus: SYSTEM_STATUS.OK,
    systemMessage: '',
  },
  mutations: {
    /**
     * set the case detail
     * @param state
     * @param detailState should be in the form {id: num, loadStatus: status, data: data}
     */
    setCaseDetail(state, detailState) {
      let newState = {...state.caseDetail};
      newState[detailState.id] = detailState;
      state.caseDetail = newState;
    },
    setUnits(state, units) {
      state.units = units;
    },
    setCaseLoadStatus(state, loadStatus) {
      state.cases.loadStatus = loadStatus;
    },
    setCaseTotal(state, total) {
      state.cases.total = total;
    },
    setActiveCaseTotal(state, total) {
      state.cases.totalActive = total;
    },
    setDataIncludesClosedCases(state, includesClosedCases) {
      state.cases = {...state.cases};
      state.cases.dataIncludesClosedCases = includesClosedCases;
    },
    addCaseDataActive(state, data) {
      state.cases = {...state.cases};
      state.cases.data  = state.cases.data.concat(data);
      if(state.cases.data.length === state.cases.totalActive) {
        state.cases.loadStatus = LOAD_STATUS.LOADED;
      }
    },
    addCaseData(state, data) {
      state.cases.data  = state.cases.data.concat(data);
      if(state.cases.data.length === state.cases.total) {
        state.cases.loadStatus = LOAD_STATUS.LOADED;
      }
    },
    resetCases(state) {
      let totalActive = state.cases.totalActive;
      state.cases = {
        loadStatus: LOAD_STATUS.NOT_LOADED, //can be NOT_LOADED, LOADING, LOADED, ERROR
        total: null,
        totalActive: totalActive,
        data: [],
        dataIncludesClosedCases: false
      }
    },
    addLecturerInfoData(state, data) {
      state.lecturerInfo = {...state.lecturerInfo};
      for(let lecInfo of data) {
        state.lecturerInfo.data[lecInfo.packetId] = lecInfo;
      }
      state.updateIndex = state.updateIndex + 1;
    },
    resetProgress(state, caseKickoffData) {
      state.latestCaseKickoff.caseKickoffData = caseKickoffData;
      state.latestCaseKickoff.progress = getDefaultProgress();
      state.latestCaseKickoff.addedToList = false;
      state.latestCaseKickoff.finished = false;
      state.latestCaseKickoff.updates = [];
    },
    setCaseKickoffSubscriber(state, kickoffUpdateSubscriber) {
      state.caseKickoffSubscriber = kickoffUpdateSubscriber;
    },
    setOmitFormStatus(state, status) {
      state.omitUnsubmittedFormsStatus = status
    },
    addKickoffUpdate(state, update) {
      state.latestCaseKickoff.progress[update.status] = true;
      state.latestCaseKickoff.updates.push(update);
      state.latestCaseKickoff.id = update.id;
      state.latestCaseKickoff.interfolioId = update.interfolioId;
      state.latestCaseKickoff.status = update.status;
      state.latestCaseKickoff.warnings = update.warnings;
      if(update.status === "Case Kickoff Complete") {
        state.latestCaseKickoff.finished = true;
      }
      //add to the list of cases
      if(!state.latestCaseKickoff.addedToList && update.interfolioId) {
        state.latestCaseKickoff.addedToList = true;
        //create new record
        let now = new Date();
        let data = state.latestCaseKickoff.caseKickoffData;
        //get the right unit
        let unitName = '';
        for(let unit of state.units) {
          if(unit.id == data.adminOrg) unitName = unit.name;
        }

        //add the new case to the case list
        let newCase  = {
          closed: false,
          closed_date: null,
          created_date: now.toISOString(),
          current_workflow_step_name: "Department Review",
          email: data.candidateEmail,
          firstname: data.candidateFirstName,
          id: state.latestCaseKickoff.interfolioId,
          lastname: data.candidateLastName,
          status: null,
          template_name: null,
          type: null,
          unit_name: unitName,
        };
        state.cases.data.push(newCase);
        state.cases.total++;

        //add the new case to lecturer info
        let newInfo = {
          actionType: data.caseAction,
          candidateName: data.candidateFirstName + " " + data.candidateLastName,
          endTerm: data.endTerm ? data.endTerm.replace('_', ' ') : "",
          lps: data.lps ? "Yes" : "No",
          packetId: state.latestCaseKickoff.interfolioId,
          startTerm: data.startTerm ? data.startTerm.replace('_', ' ') : "",
          submittedDate: now.toString(),
          title: data.title
        };
        state.lecturerInfo.data.push(newInfo);
        state.lecturerInfo.idDataMap[newInfo.packetId] = state.lecturerInfo.data.length - 1;
        state.lecturerInfo.total++;
      }
    },
    setSystemMessage(state, message) {
      state.systemMessage = message;
    },
    setSystemStatus(state, status) {
      state.systemStatus = status;
    },
    setKickoffInterfolioId(state, data) {
      let interfolioToKickoffId = {};
      let kickoffToInterfolioId = {};
      for(let item of data) {
        kickoffToInterfolioId[item.id] = item.interfolioId;
        interfolioToKickoffId[item.interfolioId] = item.id;
      }
      state.kickoffInterfolioId = {
        loadStatus: LOAD_STATUS.LOADED,
        data,
        kickoffToInterfolioId,
        interfolioToKickoffId
      }
    },
    setOfferLetter(state, data) {
      state.offerLetters = {...state.offerLetters};
      state.offerLetters[data.interfolioId] = data;
    }
  },
  actions: {
    /**
     * Iniitialize the subscription for case kickoff updates
     * @param commit
     * @param state
     * @return {Promise<void>}
     */
    async subscribeCaseKickoff({commit, state}, cognitoUser) {
      if(state.caseKickoffSubscriber === null) {
        let subscriber = state.caseKickoffUpdates = API.graphql(
          //graphqlOperation(onCreateCaseKickoff, {owner: Auth.currentAuthenticatedUser()})
          graphqlOperation(onUpdateCaseKickoffCustom, {owner: cognitoUser.username})
        ).subscribe({
          next: (response) => {
            commit('addKickoffUpdate', response.value.data.onUpdateCaseKickoff);
          }
        });
        commit('setCaseKickoffSubscriber',subscriber);
      }
    },
    async loadUnits({commit, state}) {
      if(state.units === null) {
        API.graphql(graphqlOperation(interfolioUnits))
          .then((response) => {
            commit('setUnits', response.data.interfolioUnits);
          })
          .catch(error => {
            commit('error/addError', "There was an error loading the interfolio units: " + error.message, {root: true})
          });
      }
    },
    async omitCommitteeFormRequirements({commit}, {packetId, workflowStepId, committeeId}) {
      commit('setOmitFormStatus', LOAD_STATUS.LOADING);
      API.graphql(graphqlOperation(omitUnsubmittedCommitteeForms, {packetId, workflowStepId, committeeId}))
        .then(() => {
          commit('setOmitFormStatus', LOAD_STATUS.NOT_LOADED);
        })
        .catch(error => {
          commit('error/addError', error.message, {root: true})
          commit('setOmitFormStatus', LOAD_STATUS.NOT_LOADED);
        });
    },
    async loadSystemInfo({commit}) {
      API.graphql(graphqlOperation(systemStatus))
        .then((response) => {
          commit('setSystemStatus', response.data.systemStatus.status);
          commit('setSystemMessage', response.data.systemStatus.message);
        })
        .catch(error => {
          commit('error/addError', "There was an error loading the system status: " + error.message, {root: true})
        });
    },
    async loadCaseDetail({state, commit}, caseId) {
      //if it isn't already loaded go load it
      if(!state.caseDetail.hasOwnProperty(caseId)) {
        commit('setCaseDetail', {id: caseId, loadStatus: LOAD_STATUS.LOADING, data: null});
        API.graphql(graphqlOperation(interfolioCaseDetailFull, {caseId: caseId}))
          .then((response) => {
            if(response.errors && response.errors.length > 0) {
              commit('error/addError', 'There was an error retrieving the data from Interfolio: ' + response.errors[0].message, {root: true})
              commit('setCaseDetail', {id: caseId, loadStatus: LOAD_STATUS.ERROR, data: null});
            }
            else {
              commit('setCaseDetail', {id: caseId, loadStatus: LOAD_STATUS.LOADED, data: response.data.interfolioCaseDetail});
            }
          })
          .catch((error) => {
            commit('error/addError', "There was an error loading the interfolio case detail:"  + error.message, {root: true})
          });
      }
    },
    /**
     * Iniitialize the subscription for case kickoff updates
     * @param commit
     * @param state
     * @return {Promise<void>}
     */
    async getKickoffInterfolioId({commit, state}) {
      if(state.kickoffInterfolioId.loadStatus === LOAD_STATUS.NOT_LOADED ||
        state.cases.loadStatus === LOAD_STATUS.ERROR) {

        API.graphql(graphqlOperation(kickoffInterfolioId, {})).then((response) => {
          console.log('setting interfolio mapping');
          commit('setKickoffInterfolioId', response.data.listCaseKickoffs.items);
        });
      }
    },
    async loadOfferLetterText({commit, state}, interfolioId) {
      if(!state.offerLetters.hasOwnProperty(interfolioId)
        || state.offerLetters[interfolioId].loadStatus === LOAD_STATUS.NOT_LOADED
        || state.offerLetters[interfolioId].loadStatus === LOAD_STATUS.ERROR
      )
      {
        commit('setOfferLetter', {interfolioId, loadStatus: LOAD_STATUS.LOADING, offerLetterText: null});
        API.graphql(graphqlOperation(getOfferLetterTextFromInterfolioId, {interfolioId: interfolioId}))
          .then((response) => {
            console.log(response?.data?.listCaseKickoffs?.items);
            if(response?.data?.listCaseKickoffs?.items?.length) {
              commit('setOfferLetter', {
                interfolioId,
                loadStatus: LOAD_STATUS.LOADED,
                offerLetterText: response.data.listCaseKickoffs.items[0].data.offerLetterText
              });
            }
            else {
              commit('setOfferLetter', {
                interfolioId,
                loadStatus: LOAD_STATUS.LOADED,
                offerLetterText: null
              });
            }
          })
          .catch((reason => {
            commit('setOfferLetter', {
              interfolioId,
              loadStatus: LOAD_STATUS.ERROR,
              offerLetterText: null
            });
          }));
      }
    },
    //load the cases from Interfolio via AppSync
    async loadCases({commit, dispatch, state}, from) {
      if(from === undefined) from = 0;
      if(state.cases.loadStatus === LOAD_STATUS.NOT_LOADED ||
         state.cases.loadStatus === LOAD_STATUS.ERROR ||
        (state.cases.loadStatus === LOAD_STATUS.LOADING && from > 0) ||
        (state.cases.dataIncludesClosedCases === false && from === 0)
      ) {
        if(from === 0) commit("resetCases");
        commit('setCaseLoadStatus', LOAD_STATUS.LOADING);
        commit('setDataIncludesClosedCases', true);
        API.graphql(graphqlOperation(interfolioUserCases, {from: from, size: 100, onlyActive: false}))
          .then((response) => {
            if(response.errors && response.errors.length > 0) {
              commit('error/addError', 'There was an error retrieving the data from Interfolio: ' + response.errors[0].message, {root: true})
            }
            else {
              if(response.data.interfolioUserCases.total === null) {
                commit('setCaseTotal', 0);
                commit('addCaseData', []);
              }
              else {
                commit('setCaseTotal', response.data.interfolioUserCases.total);
                commit('addCaseData', response.data.interfolioUserCases.data);
                dispatch('loadLecturerInfo', response.data.interfolioUserCases.data);
                if(state.cases.total > state.cases.data.length) {
                  dispatch('loadCases', state.cases.data.length);
                }
              }
            }
          })
          .catch(error => {
            commit('error/addError', error.message, {root: true})
          })
      }
    },
    /**
     * Load just the active cases
     * @param commit
     * @param dispatch
     * @param state
     * @param from
     * @return {Promise<void>}
     */
    async loadActiveCases({commit, dispatch, state}, from) {
      if(from === undefined) from = 0;
      if(state.cases.loadStatus === LOAD_STATUS.NOT_LOADED ||
        state.cases.loadStatus === LOAD_STATUS.ERROR ||
        (state.cases.loadStatus === LOAD_STATUS.LOADING && from > 0)
      ) {
        commit('setCaseLoadStatus', LOAD_STATUS.LOADING);
        API.graphql(graphqlOperation(interfolioUserCases, {from: from, size: 100, onlyActive: true}))
          .then((response) => {
            if(response.errors && response.errors.length > 0) {
              commit('error/addError', 'There was an error retrieving the data from Interfolio: ' + response.errors[0].message, {root: true})
            }
            else {
              if(response.data.interfolioUserCases.total === null) {
                commit('setActiveCaseTotal', 0);
                commit('addCaseDataActive', []);
              }
              else {
                commit('setActiveCaseTotal', response.data.interfolioUserCases.total);
                commit('addCaseDataActive', response.data.interfolioUserCases.data);
                dispatch('loadLecturerInfo', response.data.interfolioUserCases.data);
                if(state.cases.totalActive > state.cases.data.length) {
                  dispatch('loadActiveCases', state.cases.data.length);
                }
              }
            }
          })
          .catch(error => {
            commit('error/addError', error.message, {root: true})
          })
      }
    },


    async loadLecturerInfo({commit}, packets) {
      let packetIds = [];
      for(const index in packets) {
        packetIds.push(packets[index].id);
      }
      API.graphql(graphqlOperation(interfolioLecturerInfo, {page: 1, pageLength: 100, requestedPacketIds: packetIds}))
        .then((response) => {
          if (response.errors && response.errors.length > 0) {
            commit('error/addError', 'There was an error retrieving Lecturer info from Interfolio: ' + response.errors[0].message, {root: true})
          }
          else {
            let info = response.data.interfolioLecturerInfo;
            if(info === null) {
              commit('addLecturerInfoData', []);
            }
            else {
              commit('addLecturerInfoData', info.info);
            }
          }
        })
        .catch((error) => {
          let message = 'There was an error retrieving the additional Lecturer fields from Interfolio.  This can happen from time to time and can usually be resolved by reloading the page.';
          if (error.message) message += " Error: " + error.message;
          commit('error/addError', message, {root: true})
        });
    },
    async kickoffCase({commit}, caseKickoffData) {
        var today = new Date();
        var year = today.getFullYear();
        var month = today.getMonth() + 1;
        var day = today.getDate();
        if (month < 10) {
            month = "0" + month;
        }
      let input = {
        id: uuidv4() + "___" + year + month + day,
        data: caseKickoffData,
        status: "Case Initialization",
        interfolioId: null,
        warnings: null,
      };
      if(caseKickoffData.interfolioCaseId) {
        input.interfolioId = caseKickoffData.interfolioCaseId;
      }
      //check to make sure that we have an integer for previous enrollment
      for(let i in input.data.courses) {
        if(!parseInt(input.data.courses[i].avgEnroll)) {
          input.data.courses[i].avgEnroll = 0;
        }
        else {
          input.data.courses[i].avgEnroll = parseInt(input.data.courses[i].avgEnroll);
        }
      }
      commit('resetProgress', caseKickoffData);
      commit('addKickoffUpdate', input);
      API.graphql(graphqlOperation(createCaseKickoff, {input: input}))
        .then((response) => {
          if(response.errors && response.errors.length > 0) {
            commit('error/addError', 'There was an error kicking off the case: ' + response.errors[0].message, {root:true})
          }
        })
        .catch((error) => {
          commit('error/addError', 'There was an error kicking off the case: ' + error.errors[0].message, {root: true})
        });
    }
  },
  getters: {
    units: state => {
      return state.units;
    },
    unit: state => (unitId) => {
      for(let unit of state.units) {
        if(unit.id == unitId) return unit;
      }
      return null;
    },
    cases: state => {
      return state.cases.data
    },
    caseLoadStatus: state => {
      return state.cases.loadStatus;
    },
    totalCasesActive: state => state.cases.totalActive,
    totalCases: state => state.cases.total,
    numCasesLoaded: state => state.cases.data.length,
    dataIncludesClosedCases: state => state.cases.dataIncludesClosedCases,
    casesWithLecturerInfo: state => {
      let casesWithLecturerInfo = [];
      for(let row of state.cases.data) {
        if(state.lecturerInfo.data.hasOwnProperty(row.id)) {
          row = {...row, ...state.lecturerInfo.data[row.id]};
        }
        else {
          row = {...row,
            actionType: "[loading]",
            candidateName: "[loading]",
            endTerm: "[loading]",
            lps: "[loading]",
            packetId: 0,
            startTerm: "[loading]",
            submittedDate: "[loading]",
            title: "[loading]"
          };
        }
        casesWithLecturerInfo.push(row);
      }
      return casesWithLecturerInfo;
    },
    latestCaseKickoff: state => {
      return state.latestCaseKickoff;
    },
    kickoffCases: state => {
        return state.cases.data.filter(function(item) {
            return (
                item.current_workflow_step_name === null
                || item.current_workflow_step_name === 'Case Kickoff Integration'
              )
              && item.closed === false;
        });
    },
    caseDetail: state => (caseId) => {
      if(state.caseDetail.hasOwnProperty(caseId)) {
        return state.caseDetail[caseId];
      }
      return {
        id: caseId,
        loadStatus: LOAD_STATUS.NOT_LOADED,
        data: null
      }
    },
    kickoffComplete: state => {
      return state.latestCaseKickoff.status === "Case Kickoff Complete";
    },
    lecturerMatchesOnCourse: state => ({subjectArea, courseNumber, srsTerm}) => {
      let matches = [];
      if(state.lecturerInfo.loadStatus !== LOAD_STATUS.LOADED) return matches;
      for(let info of state.lecturerInfo.data) {
        if(termCodeToSrsTerm(info.startTerm) === srsTerm
          && JSON.stringify(info).indexOf(subjectArea + courseNumber) > -1) {
          matches.push(info);
        }
      }
      return matches;
    },
    systemStatus: state => state.systemStatus,
    systemMessage: state => state.systemMessage,
    omitUnsubmittedFormsStatus: state=>state.omitUnsubmittedFormsStatus,
    kickoffIdFromCaseId: state => (caseId) => {
      if(state.kickoffInterfolioId.interfolioToKickoffId.hasOwnProperty(caseId.toString())) {
        return state.kickoffInterfolioId.interfolioToKickoffId[caseId];
      }
      return null;
    },
    offerLetterText: state => (interfolioId) => {
      console.log(state.offerLetters);
      if(state.offerLetters.hasOwnProperty(interfolioId)) {
        return state.offerLetters[interfolioId].offerLetterText;
      }
      return null;
    }
  }
}
