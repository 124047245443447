import Vue from 'vue';
import Vuex from 'vuex';
import CourseStore from './course-store';
import UserStore from "./user-store";
import SASDataStore from "./sas-data-store";
import InterfolioStore from "./interfolio-store";
import TitleStore from "./title-store";
import ErrorStore from "./error-store";
import FormStore from "./form-store";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    course: CourseStore,
    interfolio: InterfolioStore,
    user: UserStore,
    sasData: SASDataStore,
    title: TitleStore,
    error: ErrorStore,
    form: FormStore
  },
  getters: {
    userUnits: state => {
      if(!state.user.interfolioUser) return [];
      return state.user.interfolioUser.departments;
    },
    defaultSubjectArea: (state, getters, rootState, rootGetters) => {
        if(!state.form.positionStep.adminOrg) {
            return '';
        }
        return rootGetters['sasData/getUnitDefaultSubjectArea'](state.form.positionStep.adminOrg);
    }
  }
})
