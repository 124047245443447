import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/*weebpackChunkName: "logout" */ '../components/UserCases.vue')
  },
  {
    path: '/kickoff',
    name: 'kickoff',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "kickoff" */ '../views/Kickoff.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/*weebpackChunkName: "logout" */ '../views/Logout.vue')
  },
  {
    path: '/cases',
    name: 'cases',
    component: () => import(/*weebpackChunkName: "userCases" */ '../components/UserCases.vue')
  },
  {
    path: '/complete',
    name: 'complete',
    component: () => import(/* webpackChunkName: "complete" */ '../views/KickoffComplete.vue')
  },
  {
    path: '/case-detail/:caseId',
    name: 'caseDetail',
    props: true,
    component: () => import(/* webpackChunkName: "detail" */ '../components/CaseDetail.vue')
  },
  {
    path: '/reappointments',
    name: 'reappointments',
    props: false,
    component: () => import(/* webpackChunkName: "reappointments" */ '../components/Reappointments.vue')
  },
  {
    path: '/requirements',
    name: 'requirements',
    props: false,
    component: () => import(/* webpackChunkName: "requirements" */ '../components/RequirementGrid.vue')
  },
  {
    path: '/documentTypes',
    name: 'documentTypes',
    props: false,
    component: () => import(/* webpackChunkName: "documents" */ '../components/DocumentTypes.vue')
  },
  {
    path: '/workflow',
    name: 'workflow',
    props: false,
    component: () => import(/* webpackChunkName: "workflow" */ '../components/Workflow.vue')
  },
  {
    path: '/game',
    name: 'game',
    props: false,
    component: () => import('../components/Game.vue')
  },
  {
    path: '/cpsCourses',
    name: 'cpsCourses',
    props: false,
    component: () => import(/* webpackChunkName: "complete" */ '../components/CPSCourses.vue')
  },
  {
    path: '/help',
    name: 'help',
    props: false,
    component: () => import(/* webpackChunkName: "complete" */ '../components/Help.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
