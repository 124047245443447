import {LOAD_STATUS} from "./store-utils";
import API from '@aws-amplify/api';
import { graphqlOperation } from '@aws-amplify/api';
import {interfolioUnitMapping, reappointments, cpsCourses, positionHistory} from "../graphql/queries";
import {compareTerms} from "./store-utils";

export default {
  namespaced: true,
  state: {
    unitOrgDivisionMap: {
      loadStatus: LOAD_STATUS.NOT_LOADED,
      data: [ ],
      // loadStatus: LOAD_STATUS.LOADED,
      // data: [
      //   {
      //     businessOffice: "Cohen Hall",
      //     defaultSubjectArea: "CLST",
      //     divisions: ["Humanities"],
      //     lpsUnit: false,
      //     orgCodes: "0105",
      //     unit_id: 15403,
      //     workday_unit_id: "AU00000025",
      //   }
      // ]
    },
    facultyDbHistory: {
        loadStatus: LOAD_STATUS.NOT_LOADED,
        data: {}
    },
    cpsCourses: {},
    positionHistory: {
      loadStatus: LOAD_STATUS.NOT_LOADED,
      data: []
    },
  },
  mutations: {
    addUnitOrgDivisionMap(state, unitDivisionMap) {
        for(let unit of unitDivisionMap) {
            unit.divisions = unit.divisions.split(",");
            unit.orgCodes = unit.orgCodes.split(",");
        }
        state.unitOrgDivisionMap.data = unitDivisionMap;
        state.unitOrgDivisionMap.loadStatus = LOAD_STATUS.LOADED;
    },
    addFacultyDbHistory(state, facultyDbHistory) {
        state.facultyDbHistory = {
            loadStatus: LOAD_STATUS.LOADED,
            data: facultyDbHistory
        }
    },
    setTermCPSCourses(state, termState) {
      state.cpsCourses = {...state.cpsCourses};
      state.cpsCourses[termState.srsTerm] = termState;
    },
    setPositionHistory(state, positionHistory) {
        state.positionHistory = {
            loadStatus: LOAD_STATUS.LOADED,
            data: positionHistory
        }
    },
  },
  actions: {
    async loadUnitOrgDivisionMap({commit, state}) {
      if(state.unitOrgDivisionMap.loadStatus === LOAD_STATUS.NOT_LOADED) {
        API.graphql(graphqlOperation(interfolioUnitMapping))
          .then((response) => {
            commit('addUnitOrgDivisionMap', response.data.interfolioUnitMapping);
          })
          .catch(() => {
            commit('error/addError', "There was an error retrieving Interfolio Unit Mappings from the database", {root: true})
          });
      }
    },
    async loadFacultyDbHistory({commit}) {
      API.graphql(graphqlOperation(reappointments))
        .then((response) => {
          commit('addFacultyDbHistory', response.data.reappointments);
        })
        .catch(() => {
          commit('error/addError', "There was an error retrieving Reappointment history from the database", {root: true})
        });
    },
    async loadTermCPSCourses({commit, state}, srsTerm) {
      if(!state.cpsCourses.hasOwnProperty(srsTerm) || state.cpsCourses[srsTerm].loadStatus === LOAD_STATUS.NOT_LOADED) {
        commit('setTermCPSCourses', {srsTerm, loadStatus: LOAD_STATUS.LOADING, data: []});
        API.graphql(graphqlOperation(cpsCourses, {srsTerm}))
          .then((response) => {
            commit('setTermCPSCourses', {srsTerm, loadStatus: LOAD_STATUS.LOADED, data: response.data.cpsCourses})
          })
          .catch(() => {
            commit('error/addError', "There was an error retrieving LPS Course Production System records from the database", {root: true})
          })

      }
    },
    async loadPositionHistory({commit}, {workerPennId}) {
      if(workerPennId.length !== 8) {
        commit('setPositionHistory', []);
      }
      else {
        API.graphql(graphqlOperation(positionHistory, {workerPennId: workerPennId}))
            .then((response) => {
              commit('setPositionHistory', response.data.positionHistory);
            })
            .catch(() => {
              commit('error/addError', "There was an error retrieving Workday academic position history from the database", {root: true})
            });
      }
    }
  },
  getters: {
    restictInterfolioUnitsToMappedUnits: state => (interfolioUnits) => {
      if (state.unitOrgDivisionMap.loadStatus !== LOAD_STATUS.LOADED) {
        return [];
      }
      let unitIds = [];
      for(let unit of state.unitOrgDivisionMap.data) {
        unitIds.push(unit.unit_id);
      }
      return interfolioUnits.filter((value) => {
        return unitIds.indexOf(value.id) > -1;
      })

    },
    getUnitOrgDivisionMap: state => {
      return state.unitDivisionMap;
    },
    getUnit: state => (unitId) => {
      for(let row of state.unitOrgDivisionMap.data) {
        if(row.unit_id === unitId) {
          return row;
        }
      }
      return null;
    },
    getUnitDivisions: state => (unitId) => {
      for(let row of state.unitOrgDivisionMap.data) {
        if(row.unit_id === unitId) {
          return row.divisions;
        }
      }
      return [];
    },
    getUnitLpsUnit: state => (unitId) => {
      for(let row of state.unitOrgDivisionMap.data) {
        if(row.unit_id === unitId) {
          return row.lpsUnit;
        }
      }
      return false;
    },
    getUnitDefaultSubjectArea: state => (unitId) => {
      for(let row of state.unitOrgDivisionMap.data) {
        if(row.unit_id === unitId) {
          return row.defaultSubjectArea;
        }
      }
      return false;
    },
    reappointmentAllowed: (state, getters, rootState, rootGetters) => ({adminOrg, startTerm, title}) => {
        let candidateInfo = rootGetters['form/stepData']('candidateStep');
        if(state.facultyDbHistory.loadStatus !== LOAD_STATUS.LOADED) return false;
        //cancel reappointment check for non-lecturer titles
        let titleAction = rootGetters['title/titleActionFromTitleActionYears']({title, action: 'Reappointment'});
        if(titleAction.kickoffType === "Other Academic") return true;
        for (const reapp of state.facultyDbHistory.data) {
             if(reapp.pennId == candidateInfo.candidatePennId
               && ( //title must match
                 reapp.interfolioJobTitle === title.substr(0, reapp.interfolioJobTitle.length)
                 || ( //or both titles are grad student titles
                   reapp.interfolioJobTitle.substr(0,4) === "Grad"
                   && title.substr(0, 4) === "Grad"
                 )
               )
               //unit must match
               && reapp.interfolioUnitId === adminOrg
             ) {
               //1 indicates that the start term is greater than then maxReappointment term
               if(compareTerms(startTerm, reapp.maxReappointmentTerm) === 1) {
                 return false;
               }
               return true;
             }
        }
        return false;
    },
    reappointmentLoadStatus: (state) => {
      return state.facultyDbHistory.loadStatus;
    },
    reappointments: (state) => {
      if(state.facultyDbHistory.loadStatus === LOAD_STATUS.LOADED) {
        return state.facultyDbHistory.data;
      }
      return [];
    },
    cpsCourses: (state, getters, rootState, rootGetters) => (srsTerm) => {
      if(state.cpsCourses.hasOwnProperty(srsTerm)) {
        let courses = [];
        for(let course of state.cpsCourses[srsTerm].data) {
          course.lecturerMatches = rootGetters['interfolio/lecturerMatchesOnCourse']({
            subjectArea: course.subjectArea,
            courseNumber: course.courseNumber,
            srsTerm: srsTerm
          });
          courses.push(course);
        }
        return {termCode: srsTerm, loadStatus: state.cpsCourses[srsTerm].loadStatus, data: courses};
      }
      else {
        return {termCode: srsTerm, loadStatus: LOAD_STATUS.NOT_LOADED, data: []};
      }
    },
    cpsInstructorTermCourses: state => ({srsTerm, pennId}) => {
      if(!state.cpsCourses.hasOwnProperty(srsTerm)) {
        return [];
      }
      let courses = [];
      for(let course of state.cpsCourses[srsTerm].data) {
        if(course.pennId === pennId) {
          courses.push(course);
        }
      }
      return courses;
    },
    positionHistoryLoadStatus: (state) => {
      return state.positionHistory.loadStatus;
    },
    positionHistory: (state) => {
      if(state.positionHistory.loadStatus === LOAD_STATUS.LOADED) {
        return state.positionHistory.data;
      }
      return [];
    }

  }
}
