<template>
    <div>
    <md-snackbar class="error-snackbar" :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="hasErrors" md-persistent>
        <li v-for="error in activeErrors.slice().reverse()" v-bind:key="error.index">
            <span>{{error.message}}</span>
            <md-button class="md-primary" @click="dismissError(error.index)"><md-icon>close</md-icon></md-button>
        </li>
    </md-snackbar>
    </div>
</template>

<script>
export default {
  name: 'ErrorSnackbar',
  data: () => ({
    showSnackbar: true,
    position: 'center',
    duration: 4000,
    isInfinity: true,
    errorCount: 0,
  }),
  computed: {
    activeErrors: {
      get: function() {
        return this.$store.getters['error/activeErrorMessages'];
      },
      set: function() { /*nothing*/ }
    },
    hasErrors: {
      get: function() {
        return this.$store.getters['error/activeErrorMessages'].length > 0;
      },
      set: function() { /*nothing*/ }
    }
  },
  methods: {
    dismissError(index) {
      this.$store.dispatch('error/dismissError', index);
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    .error-snackbar.md-snackbar.md-theme-default {
        background-color: #660000;
        max-height: 400px;
        padding: 3px;
    }
    .error-snackbar .md-snackbar-content {
        display: block;
    }
    h3 {
        margin: 40px 0 0;
    }
    .error-snackbar ul {
        list-style-type: none;
        padding: 0;
    }
    .error-snackbar li {
        display: block;
        margin: 3px 3px;
        padding: 5px;
        background-color: #ffcccc;
        color: #660000;
        border-bottom: 1px solid #66000;
        display: flex;
        justify-content: space-between;
    }
    a {
        color: #42b983;
    }
</style>
