<template>
  <div id="app">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <ErrorSnackbar></ErrorSnackbar>
    <md-toolbar id="nav">
        <div class="md-toolbar-section-start">
            <router-link to="/"><img id="header-logo" src="/sas_logo_white.png"></router-link>
        </div>
        <div class="md-toolbar-section-end">
            <md-list-item><router-link v-if="hasOpenCase" to="/kickoff"><md-button class="red-button">Restart Open Case</md-button></router-link></md-list-item>
            <md-menu md-size="huge" md-align-trigger v-if="hasAccess == 'AUTH_HAS_ROLES'" :md-close-on-click="true">
                <md-button md-menu-trigger :class="{ 'red-button' : !hasOpenCase}">Kickoff New Case<md-icon>expand_more</md-icon></md-button>
                <md-menu-content>
                    <md-menu-item v-if="showKickoffButtons"><span id="myId" class="my-class menu-text-content" @click="handleSaveCaseWarning">Start New</span></md-menu-item>
                    <md-menu-item><router-link to="/reappointments">From Reappointment List</router-link></md-menu-item>
                    <md-menu-item v-if="hasLpsPermission"><router-link to="/cpsCourses">From Course Production System</router-link></md-menu-item>
                </md-menu-content>
            </md-menu>
            <md-list-item><md-button><router-link v-if="hasAccess == 'AUTH_HAS_ROLES'" to="/cases">Case Dashboard</router-link></md-button></md-list-item>
            <md-menu md-size="auto" md-align-trigger v-if="hasAccess == 'AUTH_HAS_ROLES'" :md-close-on-click="true">
                <md-button md-menu-trigger>Docs<md-icon>expand_more</md-icon></md-button>
                <md-menu-content>
                    <md-menu-item><router-link to="/help">Help</router-link></md-menu-item>
                    <md-menu-item><router-link to="/requirements">Requirement Grid</router-link></md-menu-item>
                    <md-menu-item><router-link to="/documentTypes">Dossier Document Types</router-link></md-menu-item>
                    <md-menu-item><router-link to="/workflow">Approval Workflow Diagram</router-link></md-menu-item>
                    <md-menu-item><router-link to="/game">Case Kickoff Game</router-link></md-menu-item>
                </md-menu-content>
            </md-menu>
            <md-list-item><User></User></md-list-item>
        </div>
    </md-toolbar>
    <div class="system-message" v-if="systemMessage.length > 0">{{systemMessage}}</div>
    <div class="system-maintenance" v-if="systemStatus === SYSTEM_STATUS.MAINTENANCE">
      Case Kickoff is currently in maintenance mode and should return shortly
    </div>
    <div v-if="hasAccess == 'AUTH_HAS_ROLES'">
        <router-view
            v-if="systemStatus === SYSTEM_STATUS.OK"
            @updateNav="updateNav"
            @newCaseRouter="newCaseRouter"
            @clearSavedCase="clearSavedCase"
        />
        <footer class="footer">
          <div class="container">
            <div class="help-contact">
                <p>
                    <a target="_blank" href="https://iam-api.interfolio.com/users/sso_redirect?tenant_id=31697&redirect_url=https://home.interfolio.com/31697">Go to Interfolio</a>
                </p>
                <p>
                    For help, contact <a href="mailto:sas-lecturer-help@sas.upenn.edu" target="_blank">sas-lecturer-help@sas.upenn.edu</a>
                </p>
            </div>
          </div>
        </footer>
    </div>
    <div v-else-if="isLoadingRoles">
        <h1>Connecting to Interfolio...</h1>
        <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
    </div>
    <div v-else-if="hasAccess == 'AUTH_HAS_NO_ROLES'">
        <p>
            You do not have permission to access this application. For help, please email <a href="mailto:sas-lecturer-help@sas.upenn.edu">the SAS Intefolio help desk.</a>
        </p>
    </div>
    <div v-else>
        <h1>Welcome to Interfolio Case Kickoff</h1>
        <div class="no-auth"><md-button class="md-raised md-primary" @click="signIn">Login</md-button></div>
        <Game v-if="route === 'game'"></Game>
    </div>
    <div id="create-new-warning">
        <md-dialog-confirm
          :md-active.sync="showCreateNewDialog"
          md-title="Warning: You have an open case"
          md-content="You currently have an incomplete case open. Would you like to delete that case and start a new one?"
          md-confirm-text="Clear & Create New"
          md-cancel-text="Continue Working on Open Case"
          @md-cancel="newCaseRouter()"
          @md-confirm="newCaseRouter(true)" />
    </div>
    <div id="logout-warning">
        <md-dialog :md-active.sync="showLogoutWarning">
            <md-dialog-title>Your Session is About to Expire</md-dialog-title>
            <div class="md-layout md-alignment-top-center">
                <div class="md-layout-item md-size-90">
                    <p>
                      Your PennKey session will expire in {{sessionWarningMinutes}} minutes and you will be automatically logged out.
                      Would you like to logout now?
                    </p>
                </div>
            </div>
            <md-dialog-actions>
                <md-button class="md-raised md-primary" @click="signOut">Logout Now</md-button>
                <md-button class="md-raised md-accent" @click="refreshLogout">Continue Working for {{sessionWarningMinutes}} minutes</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
  </div>
</template>

<script>
import User from './components/User';
import ErrorSnackbar from './components/ErrorSnackbar';
import Game from './components/Game';
import {SYSTEM_STATUS} from './store/interfolio-store';
import {SESSION_WARNING_MINS} from "@/store/store-utils";

export default {
  name: "App",
  components: {
    ErrorSnackbar,
    User: User,
    Game: Game
  },
  created: function() {
  },
  data: function() {
      return {
          showCreateNewDialog: false,
          sessionWarningMinutes: SESSION_WARNING_MINS,
          savedForm: JSON.parse(localStorage.getItem('caseKickoffForm')) || null,
          SYSTEM_STATUS
      }
  },
  computed: {
    authenticated() {
      return this.$store.getters['user/authenticated']
    },
    hasAccess() {
      return this.$store.getters['user/hasAccess']
    },
    hasLpsPermission() {
      let apptPermission = this.$store.getters['user/appointmentPermission'];
      return (apptPermission === 'all' || apptPermission === 'lpsOnly');
    },
    showLogoutWarning() {
        return this.$store.getters['user/showLogoutWarning']
    },
    isLoadingRoles() {
        if (this.hasAccess == 'AUTH_FETCHING_ROLES' || (this.authenticated && this.hasAccess == 'NO_AUTH')) {
            return true;
        }
        return false;
    },
    hasOpenCase() {
        if (this.hasAccess == 'AUTH_HAS_ROLES' && this.savedForm) {
            return true;
        }
        return false;
    },
    showKickoffButtons() {
        if (this.$route.name == "kickoff") return false;
        return true;
    },
    isAdmin() {
      return this.$store.getters['user/isAdmin'];
    },
    route() {
      return this.$route.name;
    },
    systemStatus: function() {return this.$store.getters['interfolio/systemStatus']},
    systemMessage: function() {return this.$store.getters['interfolio/systemMessage']},
  },
  methods: {
      signIn() {
          this.$store.dispatch('user/signIn');
      },
      signOut() {
          this.$store.dispatch('user/signOut');
      },
      refreshLogout() {
          this.$store.dispatch('user/refreshLogout');
      },
      handleSaveCaseWarning() {
          if (this.hasOpenCase){
              this.showCreateNewDialog = true;
          } else {
              this.newCaseRouter();
          }
      },
      newCaseRouter(clearCase = false, presetCaseId = "", initFromStore = false) {
          this.showCreateNewDialog = false;
          if (clearCase) {
              this.clearSavedCase();
          }
          if (presetCaseId != ""){
              this.$router.push({ name: 'kickoff', query: { presetCase:  presetCaseId }});
          } else if (initFromStore) {
              localStorage.removeItem('caseKickoffForm');
              this.updateNav();
              this.$router.push({name: 'kickoff', query: {initFromStore: true}});
          } else {
              this.$router.push({ name: 'kickoff' });
          }
      },
      clearSavedCase() {
          localStorage.removeItem('caseKickoffForm');
          this.$store.dispatch('form/clearForm');
          this.updateNav();
      },
      updateNav() {
          this.savedForm = JSON.parse(localStorage.getItem('caseKickoffForm'));
      }
  }

}
</script>
<style>

@import "https://fonts.googleapis.com/css?family=Roboto:400,500,700,400italic|Material+Icons";

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  font-size: 20px;
  background-color: #1c2a4a;
}

#nav .md-list-item-content,
#nav i.md-icon,
#nav .md-button,
#nav a {
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}

#nav a.router-link-exact-active {
  color: lightgray;
}

#nav .md-button-content,
#nav .md-list-item {
    list-style-type: none;
    text-transform: none;
    font-size: 16px;
}

#header-logo {
    height: 3em;
    margin: .5em;
}

#nav .md-button{
    color: #fff;
}
#nav .md-button:hover
{
    color: #ccc;
}

#nav button.red-button,
.red-button {
    background-color: #990000;
    padding: 10px;
    border-radius: 5px;
}

span.menu-text-content {
    cursor: pointer;
}
span.menu-text-content:hover {
    text-decoration: underline;
}


.footer {
    padding: 15px;
}

div.md-dialog-actions div.md-ripple {
    position: unset;
}

.dialog-content {
    overflow-y: auto;
}
.system-message {
  margin-top: 80px;
  font-size: 30px;
  width: 80%;
  line-height: 30px;
  margin: auto;
  padding: 5px;
  background-color: #ccf;
  border: 2px dashed #99F;
}

.system-maintenance {
  margin-top: 60px;
  font-size: 60px;
  width: 80%;
  line-height: 60px;
  margin: auto;
}

</style>
