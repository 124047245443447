<template>
    <div id="game">
        <div>
            <img @click="start" v-if="!gameStarted" id="header-logo" src="/game.png" style="width: 480px; height: 270px">
            <canvas v-show="gameStarted" id="game-canvas"></canvas>
            <div id="game-buttons">
                <span v-if="gameStarted &&!gamePaused && !gameOver">
                    <button
                            style="width: 100px"
                            @mouseleave="move(0.05)"
                            @mousedown="move(-0.2)"
                            @mouseup="move(0.05)"
                            @touchstart="move(-0.2)"
                            @touchend="move(0.05)"
                    ><md-icon>arrow_upward</md-icon></button>
                </span>
                    <span>
                    <button v-if="!gameStarted" @click="start">play<md-icon>play_arrow</md-icon></button>
                </span>
                    <span v-if="gameStarted && !gamePaused && !gameOver">
                    <button @click="pause">Pause<md-icon>pause</md-icon></button>
                </span>
                    <span v-if="gameOver">
                    <button v-if="gameStarted" @click="restart">play again<md-icon>replay</md-icon></button>
                </span>
                    <span v-if="gameStarted && gamePaused">
                    <button v-if="gameStarted" @click="unPause">resume<md-icon>play_arrow</md-icon></button>
                </span>
            </div>
        </div>

        <div id="high-scores" v-if="authenticated">
            <span>High Scores</span>
            <table>
                <tbody>
                <tr v-for="(item,index) in highScores" v-bind:key="index">
                    <td class="highscore-position">{{index + 1}}.</td>
                    <td class="score">{{item.highScore}}</td>
                    <td class="high-score-name">{{item.first_name}} {{item.last_name}}</td>
                </tr>
                </tbody>
            </table>
            <hr>
            <span v-if="highScore > 0">Your High Score: {{highScore}}</span>

        </div>
        <md-dialog :md-active.sync="canSaveHighScore">
            <md-dialog-title>A New Personal High Score!</md-dialog-title>
            <md-dialog-content>
                <md-checkbox v-model="publishHighScore">Make Score Public for top 10 list</md-checkbox>
            </md-dialog-content>
            <md-dialog-actions>
                <md-button class="md-raised md-primary" @click="saveHighScore">Save High Score</md-button>
                <md-button class="md-raised" @click="cancelHighScoreSave">Cancel</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>
    var game;
    var gameInterval;

  //import { Auth } from 'aws-amplify'
  export default {
    name: 'Game',
    created: function() {
      this.$store.dispatch('user/loadHighScores');
    },
    data: function()  {
      return {
        interval: null,
        publishHighScore: true,
        highScoreSaveCheckDone: false,
        myGameArea: {
          canvas : null,
          gameOver: false,
          paused: false,
          started: false,
          myGamePiece: null,
          myObstacles:  [],
          myLevels:  [],
          myScore: 0,
          myHighScore: -200,
          myDirections: null,
          steps: [
            {text: "Case Kickoff", color: "#fbf2cb", fontSize: "20px"},
            {text: "Dossier Compilation", color: "#fbf2cb", fontSize: "20px"},
            {text: "Chair/ Director", color: "#f7e89a", fontSize: "20px"},
            {text: "Language Instruction Review*", color: "#d4baf3", fontSize: "20px"},
            {text: "School Faculty Affairs", color: "#d3e0b2", fontSize: "20px"},
            {text: "Associate Dean", color: "#bdd28e", fontSize: "20px"},
            {text: "Budget Office", color: "#d0d0ce", fontSize: "20px"},
            {text: "School Final Review*", color: "#d3e0b2", fontSize: "20px"},
            {text: "Provost Office*", color: "#FFFFFF", fontSize: "20px"},
            {text: "School Notification*", color: "#d3e0b2", fontSize: "20px"},
            {text: "Counter Signed Letter", color: "#fbf2cb", fontSize: "20px"},
            {text: "Workday Entry", color: "#adaaaa", fontSize: "20px"},
            {text: "Finalization", color: "#d3e0b2", fontSize: "20px"},
            {text: "NEXT CASE!", color: "#333333", fontSize: "32px"},
          ],
          start: function(highScore) {
            if(highScore === 0) highScore = -300;
            this.myGamePiece= new component(30, 30, "brown", 25, 120, "football", this);
            this.myGamePiece.gravity = 0.04;
            this.myScore = new component("30px Arial", "", "black", 400, 255, "text", this);
            this.myHighScore = new component(0,0,"red",highScore + 95,0,"highScore", this);
            this.myDirections = new component("italic 20px Arial", "", "grey", 400, 50, "text", this);
            this.myDirections.text = "Move the case using the ↑-button";
            this.canvas = document.getElementById('game-canvas');
            this.canvas.width = 480;
            this.canvas.height = 270;
            this.context = this.canvas.getContext("2d");
            this.frameNo = 0;
            this.paused = false;
            this.started = true;
            this.gameOver = false;
            this.myHighScore.x = highScore + 50;
          },
          restart: function (highScore) {
            if(highScore === 0) highScore = -300;
            this.frameNo = 0;
            this.myGamePiece.x = 25;
            this.myGamePiece.y = 120;
            this.myDirections.x = 400;
            this.myGamePiece.speedY = 0;
            this.myGamePiece.gravitySpeed = 0;
            this.myObstacles = [];
            this.myLevels = [];
            this.paused = false;
            this.started = true;
            this.gameOver = false;
            this.clear();
            this.myHighScore.x = highScore + 50;
          },
          clear : function() {
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
          }
        }
      }
    },
    computed: {

      username() {
        return this.$store.getters['user/username']
      },
      authenticated() {
        return this.$store.getters['user/authenticated']
      },
      gameOver() {
        return this.myGameArea.gameOver;
      },
      gameStarted() {
        return this.myGameArea.started;
      },
      gamePaused() {
        return this.myGameArea.paused;
      },
      highScore() {
        return this.$store.getters['user/highScore'];
      },
      score() {
        let gameNow = {...this.myGameArea};
        return gameNow.frameNo;
      },
      highScores() {
        return this.$store.getters['user/highScores'];
      },
      canSaveHighScore() {
        return this.highScore < this.score && this.gameOver && !this.highScoreSaveCheckDone && this.authenticated;
      }
    },
    methods: {
      start() {
        game = this.myGameArea;
        if(gameInterval) clearInterval(gameInterval);
        gameInterval = setInterval(updateGameArea, 20);
        this.myGameArea.start(this.$store.getters['user/highScore']);
        this.highScoreSaveCheckDone = false;
      },
      restart() {
        this.myGameArea.clear();
        this.myGameArea.restart(this.$store.getters['user/highScore']);
        accelerate(0.05, this.myGameArea);
        this.highScoreSaveCheckDone = false;
      },
      move(n) {
        accelerate(n, this.myGameArea);
      },
      pause() {
        this.myGameArea.paused = true;
      },
      unPause() {
        this.myGameArea.paused = false;
        updateGameArea();
      },
      saveHighScore() {
        this.highScoreSaveCheckDone = true;
        this.$store.dispatch('user/updateHighScore', {highScore: this.score, publishHighScore: this.publishHighScore});
      },
      cancelHighScoreSave() {
        this.highScoreSaveCheckDone = true;
      }
    },
    props: {
      msg: String
    }
  }

  function component(width, height, color, x, y, type, myGameArea) {
    this.myGameArea = myGameArea;
    this.type = type;
    this.score = 0;
    this.width = width;
    this.height = height;
    this.speedX = 0;
    this.speedY = 0;
    this.x = x;
    this.y = y;
    this.gravity = 0;
    this.gravitySpeed = 0;
    this.update = function() {
      let ctx = this.myGameArea.context;
      if (this.type == "text") {
        let lines = this.text.split(" ");
        ctx.font = this.width;
        ctx.fillStyle = color;
        for(let lineIndex in lines) {
          ctx.fillText(lines[lineIndex], this.x, this.y + 27 * lineIndex);
        }
      }
      else if(this.type == "highScore") {
        ctx.font = "20px arial";
        ctx.fillStyle = "red";
        ctx.fillRect(this.x, 0, 3, 280);
        ctx.rotate(Math.PI/2);
        ctx.fillText("High Score", 80, -this.x + 20);
        ctx.rotate(-Math.PI/2);
      }
      else if(this.type == "football") {
        ctx.fillStyle = color;
        ctx.beginPath();
        ctx.fillStyle = "#000099";
        ctx.arc(this.x + 10, this.y - 18, this.width*1.5, Math.PI / 4, 3 * Math.PI / 4, false); // Outer circle
        ctx.fill();
        ctx.closePath();
        ctx.fillStyle = "#990000";
        ctx.beginPath();
        ctx.arc(this.x + 10, this.y + 45, this.width*1.5, Math.PI + Math.PI / 4, Math.PI + 3 * Math.PI / 4, false); // Outer circle
        ctx.fill();
        ctx.closePath();
        //case text
        ctx.font = "bold 20px arial";
        ctx.fillStyle = "white";
        ctx.fillText("Case", this.x - 13, this.y + 20);
      } else {
        ctx.fillStyle = color;
        ctx.fillRect(this.x, this.y, this.width, this.height);
      }
    }
    this.newPos = function(myGameArea) {
      this.gravitySpeed += this.gravity;
      this.x += this.speedX;
      this.y += this.speedY + this.gravitySpeed;
      this.hitBottom(myGameArea);
    }
    this.hitBottom = function(myGameArea) {
      var rockbottom = myGameArea.canvas.height - this.height;
      if (this.y > rockbottom) {
        this.y = rockbottom;
        this.gravitySpeed = 0;
      }
      if(this.y < 0) {
        this.y = 1;
        this.speedY = 0;
        this.gravitySpeed = 0.4;
      }
    }
    this.crashWith = function(otherobj) {
      var myleft = this.x;
      var myright = this.x + (this.width);
      var mytop = this.y;
      var mybottom = this.y + (this.height);
      var otherleft = otherobj.x;
      var otherright = otherobj.x + (otherobj.width);
      var othertop = otherobj.y;
      var otherbottom = otherobj.y + (otherobj.height);
      var crash = true;
      if ((mybottom < othertop) || (mytop > otherbottom) || (myright < otherleft) || (myleft > otherright)) {
        crash = false;
      }
      return crash;
    }
  }

  function updateGameArea() {
    var myGameArea = game;
    if(!myGameArea.paused) {
      var x, height, gap, minHeight, maxHeight;
      for (let i = 0; i < myGameArea.myObstacles.length; i += 1) {
        if (myGameArea.myGamePiece.crashWith(myGameArea.myObstacles[i])) {
          myGameArea.gameOver = true;
          myGameArea.myGamePiece.speedY = 0;
          myGameArea.myGamePiece.gravitySpeed = 0;
          return;
        }
      }
      myGameArea.clear();
      myGameArea.frameNo += 1;
      if (myGameArea.frameNo == 1 || everyinterval(150, myGameArea)) {
        x = myGameArea.canvas.width;
        //set the gap to decrease over time
        gap = 180 - myGameArea.frameNo/40;
        if(gap < 65) gap = 65;
        minHeight = 10;
        maxHeight = 270 - gap -10;
        height = Math.floor(Math.random() * (maxHeight - minHeight + 1) + minHeight);
        //add the green barriers
        myGameArea.myObstacles.push(new component(10, height, "green", x, 0, "box", myGameArea));
        myGameArea.myObstacles.push(new component(10, x - height - gap, "green", x, height + gap, "box", myGameArea));

        //add the workflow step text and background color
        let stepIndex = (myGameArea.myLevels.length / 2) % myGameArea.steps.length;
        //add the NEXT STEP level
        if(stepIndex === myGameArea.steps.length -1) {
          myGameArea.myLevels.push(new component(150, 280, myGameArea.steps[stepIndex].color, x + 5, 0, "box", myGameArea));
          myGameArea.myLevels.push(new component(myGameArea.steps[stepIndex].fontSize + " arial", '', "white", 500, 50, "text", myGameArea))
          myGameArea.myLevels[myGameArea.myLevels.length - 1].text = myGameArea.steps[stepIndex].text;
        }
        //add all other levels
        else {
          myGameArea.myLevels.push(new component(150, 280, myGameArea.steps[stepIndex].color, x + 5, 0, "box", myGameArea));
          myGameArea.myLevels.push(new component(myGameArea.steps[stepIndex].fontSize + " arial", '', "black", 500, 20, "text", myGameArea))
          myGameArea.myLevels[myGameArea.myLevels.length - 1].text = myGameArea.steps[stepIndex].text;
        }
      }
      //draw the levels
      for (let i = 0; i < myGameArea.myLevels.length; i += 1) {
        myGameArea.myLevels[i].x += -1;
        myGameArea.myLevels[i].update(myGameArea);
      }
      //draw the obstacles
      for (let i = 0; i < myGameArea.myObstacles.length; i += 1) {
        myGameArea.myObstacles[i].x += -1;
        myGameArea.myObstacles[i].update(myGameArea);
      }
      myGameArea.myHighScore.x += -1;
      myGameArea.myDirections.x += -1;

      myGameArea.myScore.text = myGameArea.frameNo.toString();
      myGameArea.myDirections.update(myGameArea);
      myGameArea.myScore.update(myGameArea);
      myGameArea.myHighScore.update();
      myGameArea.myGamePiece.newPos(myGameArea);
      myGameArea.myGamePiece.update(myGameArea);
    }
  }

  function everyinterval(n, myGameArea) {
    if ((myGameArea.frameNo / n) % 1 == 0) {return true;}
    return false;
  }

  function accelerate(n, myGameArea) {
    myGameArea.myGamePiece.gravity = n;
  }


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h3 {
        margin: 40px 0 0;
    }
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        display: inline-block;
        margin: 0 10px;
    }
    a {
        color: #42b983;
    }
    canvas {
        border: 5px solid green;
        width: 480px;
        height: 270px;
        margin: 5px 0px;
    }
    button {
        margin: 5px;
    }
    #game {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
    }
    .high-score-name {
        text-align: left;
        padding-left: 10px;
    }
    .highscore-position {
        text-align: left;
    }
    .score {
        text-align: right;
    }
</style>
