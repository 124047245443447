import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import { Hub, Credentials } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import {cognitoUser } from "../graphql/queries";
import {highScores} from "../graphql/queries-custom";
import {updateHighScore} from "../graphql/mutations-custom";
import { AUTH_STATUS, SESSION_LENGTH_HOURS, SESSION_WARNING_MINS, LOAD_STATUS } from "./store-utils";
import {interfolioUser} from "../graphql/queries-custom";
import router from "../router";

export default {
  namespaced: true,
  state: {
    authenticated: false,
    hasAccess: AUTH_STATUS.NO_AUTH,
    cognitoUser: null,
    cognitoUserInfo: null,
    interfolioUserCommittees: null,
    interfolioUser: null,
    showLogoutWarning: false,
    logoutTime: null,
    highScoresLoadStatus: LOAD_STATUS.NOT_LOADED,
    highScores: []
  },
  mutations: {
    setCognitoUser(state, cognitoUser) {
      state.cognitoUser = cognitoUser;
    },
    setCognitoUserInfo(state, cognitoUserInfo) {
      state.cognitoUserInfo = cognitoUserInfo;
    },
    setAuthenticated(state, authenticated) {
      state.authenticated = authenticated;
    },
    setLogoutWarning(state, showWarning) {
      state.showLogoutWarning = showWarning;
    },
    setLogoutTime(state, logoutTime) {
      state.logoutTime = logoutTime;
    },
    setInterfolioUser(state, user) {
      state.interfolioUser = user;
      if(user !== null) {
        state.interfolioUserCommittees = user.committee_member_committees;
        if (state.interfolioUser.departments.length > 0) {
          state.hasAccess = AUTH_STATUS.AUTH_HAS_ROLES;
        } else {
          state.hasAccess = AUTH_STATUS.AUTH_HAS_NO_ROLES;
        }
      }
    },
    setHasNoRoles(state) {
      state.hasAccess = AUTH_STATUS.AUTH_HAS_NO_ROLES;
    },
    setHighScores(state, {highScores, loadStatus}) {
      state.highScores = highScores;
      state.highScoresLoadStatus = loadStatus;
    }
  },
  actions: {
    refreshLogout({commit}) {
      commit('setLogoutWarning', false);
      // // add two hours
      // let newLogoutTime = new Date(state.logoutTime);
      // // newLogoutTime.setMinutes(newLogoutTime.getMinutes() + SESSION_EXT_HOURS); // for testing
      // newLogoutTime.setHours(newLogoutTime.getHours() + SESSION_EXT_HOURS);
      // commit('setLogoutTime', newLogoutTime);
      //
      // // let secondsUntilAutoLogout = (SESSION_EXT_HOURS * 60) + (SESSION_WARNING_MINS * 60); // for testing
      // let secondsUntilAutoLogout = (SESSION_EXT_HOURS * 60 * 60) + (SESSION_WARNING_MINS * 60);
      // let secondsUntilAutoLogoutWarning = secondsUntilAutoLogout - (SESSION_WARNING_MINS * 60);
      // setTimeout(() => { dispatch('autoLogoutWarning'); }, secondsUntilAutoLogoutWarning * 1000);
      // setTimeout(() => { dispatch('autoLogout'); }, secondsUntilAutoLogout * 1000);
    },
    async init({dispatch, commit}) {
      Auth.currentAuthenticatedUser().then(user => {
        dispatch('setUser', user)
      })
        .catch(() => {
          commit('setAuthenticated', false);
          commit('setCognitoUser', null);
          commit('setInterfolioUser', null);
        });


      Hub.listen('auth', ({payload}) => {
        // if(payload.event === 'signIn' || payload.event === 'cognitoHostedUI') {
        if(payload.event === 'cognitoHostedUI') {
          dispatch('setUser', payload.data);
        }
      });
    },
    async setUser({commit, dispatch}, cognitoUser) {
      Auth.currentSession()
        .then((session) => {
          let authTime = new Date(session.accessToken.payload.auth_time * 1000);
          let logoutTime = new Date(authTime);
          // logoutTime.setMinutes(authTime.getMinutes() + SESSION_LENGTH_HOURS); // for testing
          logoutTime.setHours(authTime.getHours() + SESSION_LENGTH_HOURS);
          commit('setLogoutTime', logoutTime);
          let now = Date.now();
          let secondsSinceAuth = now/1000 - session.accessToken.payload.auth_time;
          //let allowedSessionSeconds = 60 * SESSION_LENGTH_HOURS; // for testing
          let allowedSessionSeconds = 60 * 60 * SESSION_LENGTH_HOURS;
          if(secondsSinceAuth > allowedSessionSeconds) {
            //logout now
            dispatch("autoLogout");
          }
          else {
            let secondsUntilAutoLogout = allowedSessionSeconds - secondsSinceAuth;
            //get 5 minute warning time
            let warningSeconds = SESSION_WARNING_MINS * 60;  //set warning time five minutes before logout
            let secondsUntilAutoLogoutWarning = secondsUntilAutoLogout - warningSeconds;
            if(secondsUntilAutoLogoutWarning < 0) {
              dispatch('autoLogoutWarning');
            }
            else {
              setTimeout(() => { dispatch('autoLogoutWarning'); }, secondsUntilAutoLogoutWarning * 1000);
            }
            setTimeout(() => { dispatch('autoLogout'); }, secondsUntilAutoLogout * 1000);
            commit('setCognitoUser', cognitoUser);
            commit('setAuthenticated', true);
            dispatch('loadInterfolioUser');
            dispatch('loadCognitoUserInfo');
            dispatch('interfolio/loadUnits', {}, {root: true});
            dispatch('interfolio/subscribeCaseKickoff',cognitoUser, {root: true});
            dispatch('interfolio/loadSystemInfo', {}, {root:true});
            dispatch('title/loadData', {}, {root: true});
          }
        });
    },
    autoLogoutWarning({commit}) {
      commit('setLogoutWarning', true);
    },
    autoLogout({state, commit}) {
      if (Date.now() > state.logoutTime) {
        //Auth.signOut();
        Credentials.clear()
          .then(() => {
            commit('setAuthenticated', false);
            commit('setCognitoUser', null);
            commit('setLogoutWarning', false);
          })
        router.push('logout');
      }
    },
    signIn() {
      Auth.federatedSignIn();
    },
    async signOut({commit}) {
      Auth.signOut();
      commit('setAuthenticated', false);
      commit('setCognitoUser', null);
    },
    async loadHighScores({commit}) {
      API.graphql(graphqlOperation(highScores))
        .then(response => {
          let highScores = response.data.listInterfolioUsers.items;
          highScores = highScores.sort((a,b) => a.highScore > b.highScore);
          commit('setHighScores', {loadStatus: LOAD_STATUS.LOADED, highScores});
        })
    },
    async loadInterfolioUser({commit, dispatch, state}) {
      if(state.authenticated && state.interfolioUser === null) {
        state.hasAccess = AUTH_STATUS.AUTH_FETCHING_ROLES;
        API.graphql(graphqlOperation(interfolioUser, {}))
          .then((response) => {
            dispatch('interfolio/loadActiveCases', 0, {root: true});
            dispatch('sasData/loadFacultyDbHistory', null, {root: true});
            commit('setInterfolioUser', response.data.interfolioUser);
          })
          .catch(error => {
            if(error.errors && error.errors[0] && error.errors[0].message) {
              commit('error/addError', error.errors[0].message, {root: true})
            }
            else {
              commit('error/addError', error, {root: true})
            }
            commit('setHasNoRoles');
          });
      }
    },
    async loadCognitoUserInfo({commit, state}) {
      if(state.authenticated) {
        API.graphql(graphqlOperation(cognitoUser))
          .then((response) => {
            commit('setCognitoUserInfo', response.data.cognitoUser);
          })
          .catch(error => {
            commit('error/addError', error.message, {root: true})
          });
      }

    },
    async updateHighScore({commit, dispatch}, {highScore, publishHighScore}) {
      API.graphql(graphqlOperation(updateHighScore, {highScore, publishHighScore}))
        .then((response) => {
          commit('setInterfolioUser', response.data.updateHighScore);
          dispatch('loadHighScores');
        })
        .catch(error => {
          commit('error/addError', error.message, {root: true})
        });
    }
  },
  getters: {
    authenticated: state => state.authenticated,
    hasAccess: state => state.hasAccess,
    showLogoutWarning: state => state.showLogoutWarning,
    username: state => {
      if(!state.authenticated) return "no user";
      return state.cognitoUser.username
        .replace("penn-web-login-sso_","")
        .replace("@upenn.edu", "");
    },
    interfolioUser: state => {
      return state.interfolioUser
    },
    interfolioUserCommittees: state => {
      if(state.interfolioUserCommittees === null) return [];
      return state.interfolioUserCommittees;
    },
    cognitoUserInfo: state => {
      return state.cognitoUserInfo;
    },
    appointmentPermission: state => {
      if(state.interfolioUser === null) return "";
      return state.interfolioUser.appointmentPermission;
    },
    isAdmin: state => {
      if(state.interfolioUserCommittees) {
        for(let committee of state.interfolioUserCommittees) {
          if(committee.name === 'School Faculty Affairs') {
            return true;
          }
        }
      }
      return false;
    },
    highScore: state => {
      if(state.interfolioUser === null) return 0;
      if(state.interfolioUser.highScore === null) return 0;
      return state.interfolioUser.highScore;
    },
    highScores: state => {
      let ordered = state.highScores.sort((a,b) => (a.highScore > b.highScore) ? -1 : 1);
      let top10 = ordered.slice(0, 10);
      for(let i = top10.length; i < 10; i++) {
        top10.push({last_name: "-", first_name: "-", highScore: 0})
      }
      return top10;
    }

  }
}
