
export const kickoffCaseFullDetail = /* GraphQL */ `
  mutation KickoffCase($caseKickoffData: CaseKickoffData) {
    kickoffCase(caseKickoffData: $caseKickoffData) {
      warnings {
        message
        detail
      }
      case {
        id
        candidate_first_name
        candidate_last_name
        candidate_email
        archived
        candidate_involvement
        applicant_pid
        allow_additional_documents
        unit_id
        created_at
        due_date
        packet_template_name
        packet_type_id
        institution_id
        applicant_id
        current_workflow_step {
          id
          step_number
          current
          due_date
          created_at
          name
          note
        }
        unit_name
        status
        current_users_assigned_committees {
          id
          name
        }
        workflow_steps {
          id
          step_number
          current
          due_date
          created_at
          name
          note
          committees {
            id
            name
            committee_member_count
            type
            standing
            unit_id
            committee_members {
              id
              pid
              first_name
              last_name
              email
              user_id
              committee_id
              temporary
              is_user
              manager
              recused
            }
          }
        }
        next_workflow_step {
          id
          step_number
          current
          due_date
          created_at
          name
          note
        }
        previous_workflow_step {
          id
          step_number
          current
          due_date
          created_at
          name
          note
        }
        can_be_moved_forward
        waiting_on_committee
      }
    }
  }
`;


export const updateHighScore = /* GraphQL */ `
  mutation UpdateHighScore($highScore: Int!, $publishHighScore: Boolean) {
    updateHighScore(highScore: $highScore, publishHighScore: $publishHighScore) {
      cognitoUsername
      sso_id
      id
      first_name
      last_name
      email
      pid
      committee_member_committees {
        id
        unit_id
        name
        type
        committee_member_id
        manager
      }
      titles {
        id
        name
        unit_id
        unit_name
        title_association_id
        can_be_deleted
      }
      departments {
        id
        name
        ancestor_institution_id
        parent_unit_id
        child_unit_ids
      }
      appointmentPermission
      lastCaseKickoffLogin
      highScore
      publishHighScore
    }
  }
`;
