const ONBOARDING_TEXT = `Onboarding Process
You will receive two important communications as a part of the onboarding process:

  1. An email with instructions for setting up your Pennkey and password.
  2. A welcome email from Workday with instructions for completing your onboarding tasks.

Your Pennkey and password are required to access many of Penn’s systems, including Workday and SecureShare. Before your first day of work at Penn, you will need to complete the University’s electronic onboarding process in Workday. You must:

  A. Provide Verification of Your Identity and Right to Work in the United States.
    Per the Immigration Reform and Control Act of 1986, the University is required to confirm your identity and right to work in the United States. To meet this requirement, employers must have a completed Form I-9 for every employee. You will be asked to complete Section 1 of the Form I-9 during the electronic onboarding process. When you begin work, you must present the original, unexpired documentation in person in order for us to complete Section 2 of the Form I-9. It is helpful if you provide this documentation on or before your first day of work, but it must be presented no later than the third business day after you begin work, by doing the following:

    • Review the Lists of Acceptable Documents for materials that may be presented to confirm your identity and employment authorization. Visit Form I-9 Acceptable Documents. You must present one (1) document from List A (Documents That Establish Identity and Employment Authorization) – OR – one (1) document from List B (Documents That Establish Identity) and one (1) document from List C (Documents that Establish Employment Authorization).

    • Form I-9 Section 2 must be completed in person. See the Onboard@Penn website for additional details and to schedule an appointment. Proceed to Onboard@Penn (3425 Walnut Street) with unexpired, original documents. • For employees who work outside Pennsylvania, New Jersey, and Delaware, please see the Onboard@Penn website for remote Form I-9 instructions.

  B. Provide Your Social Security Card.
    The University uses the Social Security Administration’s Social Security Number Verification Service (SSNVS) to verify the Social Security Number of each new hire for payroll purposes. You are expected to present your Social Security Card when you begin working so that your Social Security Number can be verified. If you have misplaced your Social Security Card and need a replacement, you can apply for a replacement card at your local Social Security Administration office. You also may be able to apply for a replacement card online. Visit Social Security Number and Card for additional information.

  The Social Security Administration office closest to the University is located at 2 Penn Ctr, Ste 2000B, 1500 JFK Blvd, Philadelphia, PA 19102. For information regarding other Social Security Administration offices, visit the Social Security Administration Office Locator.\`,
`;

const SIGNATURE_TEXT = `Please let me know by %%%ReceiptDate%%% if you accept this offer.  We look forward to having you as a member of the Department in the coming year.

Sincerely,


%%%ChairName%%%
%%%ChairTitle%%%

Accept:  ______________________________\t\t______________________
         Name                          \t\tDate

My signature above indicates my acceptance of this offer and its terms, and my authorization to receive my pay as outlined above. I understand that continued satisfactory performance is a necessary condition of continued employment. I acknowledge that my employment is at will, that this offer is not an employment contract, and that my employment may be terminated at any time for any reason, including but not limited to unsatisfactory performance, misconduct, workforce restructuring or completion of my temporary assignment.

My signature above also indicates that as an employee of the University, I am placed in a position of confidence and trust. My appointment may give me access to confidential information, the unauthorized disclosure of which could cause irreparable damage to students, staff, faculty, alumni, patients, affiliates, agents or contractor of the University.  In accepting this position, I agree that during and after my employment with the University, I will not use or disclose any confidential information except as may be necessary and appropriate in fulfillment of my duties, and I further agree to maintain the confidentiality and security of University information in accordance with University information-security policies as they may be amended from time to time. I also agree to maintain the confidentiality of my password for all systems that I use to access confidential information.

[ ] By checking this box and signing my name above, I acknowledge that my signature, whether electronic or otherwise, constitutes as a legal signature.\`,
`;

const FT_SALARY_TEXT = 'Your nine-month academic year salary will be %%%salary%%%.  You may choose to receive your payments over nine months (September 1, %%%startYear%%% through May 31, %%%endYear%%%) or twelve months (July 1, %%%startYear%%% through June 30, %%%endYear%%%) depending upon your arrival.  A summary of the University benefits may be found online at https://www.hr.upenn.edu/pennbenefits.  The benefit choices must be made within one month of your appointment.  For answers to questions, please contact a representative of the Benefits Solution Center at toll free number 1-866-799-2329.';
const PT_SALARY_TEXT = `Your salary will be %%%salary%%%.  As defined in this letter, your position is not benefits eligible under federal law and University policy. If you are now or do in the future perform compensated work for another school or unit that is not currently addressed in this letter, please let me know as assignments in other Penn schools or units will affect your benefits eligibility, and will need to be coordinated accordingly.`;
const PT_TEACHING_TEXT = `Under University and School guidelines it is generally expected that work for each of these courses would entail 10 hours per week of faculty time related to delivery of instruction, research, preparation, and meeting time with students`
export const OFFER_LETTER_TYPE = {
  LECTURER_PART_TIME: "Lecturer (Part Time)",
  LECTURER_FULL_TIME: "Lecturer (Full Time)",
  NONE: "none"
};

function OfferLetter() {
  this.offerLetterType = OFFER_LETTER_TYPE.LECTURER_PART_TIME;
  this.candidateFirstName = '[candidateFirstName]';
  this.candidateLastName = '[candidateLastName]';
  this.actionType = '[actionType]';
  this.candidateAddress = '[candidateAddress]';
  this.deptProgram = '[deptProgram]';
  this.needsVisa = false;
  this.receiveByDate = '[receiveByDate]';
  this.numberOfCourses = '[numberOfCourses]';
  this.salary = '[salary]';
  this.additionalBenefits = '[additionalBenefits]';
  this.semesterRange = '[semesterRange]';
  this.semester1 = '[semester1]';
  this.semester1Courses = '[semester1Courses]';
  this.semester2 = null;
  this.semester2Courses = null;
  this.semester3 = null;
  this.semester3Courses = '[semester3Courses]';
  this.additionalDuties = '[additionalDuties]';
  this.additionalTimeExpectations = '[additionalTimeExpectations]';
  this.chairName = '[chairName]';
  this.chairTitle = '[chairTitle]';
  this.reappointmentDeanApproval = false;
  this.title = null;
  this.startYear = null;

  this.getText = function () {
    let v_letter_text = '';

    let today = new Date();
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    v_letter_text = monthNames[today.getMonth()] + " " + today.getDate() + ", " + today.getFullYear() + "\n\n";

    v_letter_text += this.candidateFirstName + " " + this.candidateLastName + "\n";
    v_letter_text += this.candidateAddress + "\n\n";

    v_letter_text += 'Dear ' + this.candidateFirstName + ' ' + this.candidateLastName + ":\n\n";

    v_letter_text += "I am delighted to offer you an appointment as a " + this.getFormattedTitle() + " in the ";
    v_letter_text += this.deptProgram + " at the University of Pennsylvania for " + this.semesterRange + ".";

    v_letter_text += " The appointment is contingent on approval by the Dean of the School of Arts and Sciences.";
    v_letter_text += this.getDeanReappointmentText();
    if (this.needsVisa) v_letter_text += " It is also contingent upon your receipt of the appropriate U.S. Visa.";

    v_letter_text += "\n\nYour duties will include teaching " + this.numberOfCourses + " course(s).\n";
    v_letter_text += "In " + this.semester1 + " you will teach the following course(s):\n" + this.semester1Courses;
    if (this.semester2) v_letter_text += "\nIn " + this.semester2 + " you will teach the following course(s):\n" + this.semester2Courses;
    if (this.semester3) v_letter_text += "\nIn " + this.semester3 + " you will teach the following course(s):\n" + this.semester3Courses;

    if (this.additionalDuties) v_letter_text += "\n" + this.additionalDuties;

    //add part time teaching and text
    if(this.offerLetterType === OFFER_LETTER_TYPE.LECTURER_PART_TIME) {
      v_letter_text += "\n\n" + PT_TEACHING_TEXT;
      if (this.additionalTimeExpectations) {
        v_letter_text += ', as well as ' + this.additionalTimeExpectations;
      }
    }

    //add salary text
    v_letter_text += this.getSalaryText();

    if (this.actionType === "Appointment") v_letter_text += "\n\n" + ONBOARDING_TEXT;
    v_letter_text += "\n\n" + SIGNATURE_TEXT.replace('%%%ChairName%%%', this.chairName)
      .replace('%%%ChairTitle%%%', this.chairTitle)
      .replace('%%%ReceiptDate%%%', this.receiveByDate);

    return v_letter_text;
  };

  this.set = function (field, value) {
    this[field] = value;
  };

  //initialize the offer letter data from the vue form data
  this.initializeFormData = function (storeGetters) {

    let currentTitleAction = storeGetters['title/currentTitleAction'];
    if(currentTitleAction) {
      this.setTitleAction(currentTitleAction);
    }

    //set fields from other steps
    let formData = storeGetters['form/formData'];

    if (formData.positionStep?.adminOrg) {
      this.setDepartmentFromInterfolioName(storeGetters['interfolio/unit'](formData.positionStep.adminOrg).name);
    }

    this.candidateFirstName = formData.candidateStep.candidateFirstName;
    this.candidateLastName = formData.candidateStep.candidateLastName;
    this.numberOfCourses = formData?.coursesStep?.courses?.length || 0;
    this.salary = formData.fundingStep.proposedSal;
    this.setSemesterRange(formData);
    this.setSemesterCourses(formData);
    this.setStartYear(formData);

    if(formData.offerLetterStep) {
      this.chairName = formData.offerLetterStep.chairName;
      this.chairTitle = formData.offerLetterStep.chairTitle;
      this.receiveByDate = formData.offerLetterStep.receiveByDate;
      this.additionalDuties = formData.offerLetterStep.additionalDuties;
      this.additionalTimeExpectations = formData.offerLetterStep.additionalTimeExpectations;
      this.additionalBenefits = formData.offerLetterStep.additionalBenefits;
      this.needsVisa = formData.offerLetterStep.needsVisa;
      this.reappointmentDeanApproval = formData.offerLetterStep.reappointmentDeanApproval;
    }
  }

  this.setTitleAction = function(titleAction) {
    this.offerLetterType = titleAction.offerLetterType;
    this.title = titleAction.title;
  }

  //get the text for the dean reappointment
  this.getDeanReappointmentText = function() {
    if(this.offerLetterType === OFFER_LETTER_TYPE.LECTURER_FULL_TIME && this.reappointmentDeanApproval) {
      return '  The appointment is renewable on an annual basis for up to two additional years subject to satisfactory performance and approval by the Dean.';
    }
    return '';
  }

  this.getSalaryText = function() {
    let salaryText = "\n\n";
    if(this.offerLetterType === OFFER_LETTER_TYPE.LECTURER_PART_TIME) {
      return salaryText + PT_SALARY_TEXT.replace("%%%salary%%%", this.salary) + " " + this.additionalBenefits;
    }
    else if(this.offerLetterType === OFFER_LETTER_TYPE.LECTURER_FULL_TIME) {
      return salaryText + FT_SALARY_TEXT.replace("%%%salary%%%", this.salary)
        .replaceAll('%%%startYear%%%', this.startYear.toString())
        .replaceAll('%%%endYear%%%', (this.startYear +1).toString())
    }
    return ''; 

  }

  this.setStartYear = function(formData) {
    if(formData?.positionStep?.startTerm) {
      this.startYear = parseInt(formData.positionStep.startTerm.substr(0,4));
    }
    else {
      this.startYear = new Date().getFullYear();
    }
  }

  this.setDepartmentFromInterfolioName = function (interfolioName) {
    if (interfolioName.indexOf('School of Arts and Sciences -') === 0) {
      this.deptProgram = interfolioName.replace('School of Arts and Sciences -', 'Department of ');
    }
    else if (interfolioName.indexOf('Center') >= 0) {
      this.deptProgram = interfolioName;
    }
    else if (interfolioName.indexOf('Program') >= 0) {
      this.deptProgram = interfolioName;
    }
    else {
      this.deptProgram = interfolioName + ' Program';
    }
  }

  this.setSemesterRange = function (formData) {
    if(this.offerLetterType === OFFER_LETTER_TYPE.LECTURER_PART_TIME) {
      let semesterStart = this.convertTermCodeToTerm(formData.positionStep.startTerm);
      let semesterEnd = this.convertTermCodeToTerm(formData.positionStep.endTerm);
      if (semesterStart === semesterEnd) {
        this.semesterRange = "the " + semesterStart + " semester";
      } else {
        this.semesterRange = "the " + semesterStart + " through " + semesterEnd + " semesters";
      }
    }
    else {
      let startYear = parseInt(formData.positionStep.startTerm.substr(0,4));
      this.semesterRange = "the " + startYear.toString() + "-" + (startYear+1).toString() + " academic year";
    }
  }

  this.convertTermCodeToTerm = function(termCode) {
    if (typeof termCode !== 'string') return '~';
    return termCode.replaceAll("_", " ")
      .replace('FALL', 'Fall')
      .replace('SUMMER', 'Summer')
      .replace('SPRING', 'Spring');
  }

  this.setSemesterCourses = function(formData) {
    let semesters = {};
    let courses = formData?.coursesStep?.courses;
    if(courses) {
      for (let course of courses) {
        let term = this.convertTermCodeToTerm(course.courseTerm);
        if (semesters.hasOwnProperty(term)) {
          semesters[term].push(course);
        } else {
          semesters[term] = [course];
        }
      }
    }
    let semesterNum = 0;
    for(let term in semesters) {
      semesterNum++;
      this['semester' + semesterNum.toString()] = term;
      let courseString = "";
      for(let course of semesters[term]) {
        courseString += course.subjectArea + " " + course.courseNum;
        if(parseInt(course.numSecs) > 1) courseString += " (" + course.numSecs + " sections)";
        courseString += "\n";
      }
      this['semester' + semesterNum.toString() + "Courses"] = courseString;
    }
  }
  this.getFormattedTitle = function() {
    return this.title;
  }
}

export default OfferLetter;