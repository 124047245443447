<template>
    <span>
        <md-button v-if="!authenticated" v-on:click="signIn()">Login</md-button>
        <md-button v-if="authenticated" v-on:click="signOut()">Logout {{ username }} </md-button>
    </span>
</template>

<script>
  //import { Auth } from 'aws-amplify'
  export default {
    name: 'User',
    data: function()  {
      return {
        loginUrl:  process.env.VUE_APP_SIGNIN_URL
      }
    },
    computed: {
      username() {
        return this.$store.getters['user/username']
      },
      authenticated() {
        return this.$store.getters['user/authenticated']
      },
      units() {
        return this.$store.getters['userUnits']
      }
    },
    methods: {
      signIn() {this.$store.dispatch('user/signIn')},
      signOut() {this.$store.dispatch('user/signOut')}
    },
    props: {
      msg: String
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h3 {
        margin: 40px 0 0;
    }
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        display: inline-block;
        margin: 0 10px;
    }
    a {
        color: #42b983;
    }
</style>
